import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import About from './components/About'
import MyStory from './components/MyStory'
import Training from './components/Training';
import Research from './components/Research';
import HonorAwards from './components/HonorAwards';
import Testimonials from './components/Testimonials';
import ContactMe from './components/ContactMe';
import ScrollToTop from './components/ScrollToTop'


function App() {
  return (
    <div className="app">
      <Router>
      <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<About />} />
          <Route path="/my-story" element={<MyStory />} />
          <Route path="/master-class" element={<Training />} />
          <Route path="/projects" element={<Research />} />
          <Route path="/awards" element={<HonorAwards />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact-me" element={<ContactMe />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
