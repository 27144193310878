import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import Navbar from './Navbar';
import Footer from './Footer';


import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from '@cloudinary/react';


function Testimonials() {

  var cld;
  cld = new Cloudinary({
    cloud: {
      cloudName: 'drsqh0aog'
    }
  });

  var mainCoverPhoto = cld.image('/Owais/coverphotoTestimonials_obiwjx.jpg');


  var settings = {
    dots: true,
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <div className='main'>
      <Navbar />
      <div>
        <div className='mainImage'>
          <div className="mainPageHeading">Testimonials</div>
          <AdvancedImage cldImg={mainCoverPhoto} />
        </div>
        <div className='researchHeading'>
          <h1>Consultant</h1>
        </div>
        <Slider {...settings} adaptiveHeight={false}
        >
          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">Muhammad Owais Awan is a
                passionate young guy, and it is a
                pleasure to work with him,
                because you can always have
                proactive & productive discussion
                with him. Every time I work with
                him, though from afar, I got a
                feeling that I am talking to a
                person who keeps opening new
                doors with a good logical and
                management mind. So, I am
                sincerely looking forward to
                having several challenges to
                tackle with him!</p>
            </div>

            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/ChieSato.png')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Chie Sato</div>
                <div className="designation">President - Biztech Inc.</div>
                <div className="location">Tokyo, Japan</div>
              </div>
            </div>
          </div>

          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">Muhammad is an incredible young
                professional with a futuristic vision
                who is contributing ideas and
                initiatives that help achieve some of
                the goals of the SDGs.

                I had the
                opportunity to work with him in a
                challenge about Updating and
                Reinventing Education in Public
                Schools, where he proved to be a
                valuable member of the team by
                being collaborative, creative and
                always giving his best. He is a
                professional who makes all the
                difference in the team and I hope his
                professional journey is full of
                successes.</p>
            </div>
            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/Estacio.jfif')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Estácio Armando Buchuo</div>
                <div className="designation">CEO - Elevate Multimedia</div>
                <div className="location">Maputo, Mozambique</div>
              </div>
            </div>
          </div>

          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">When you see someone with
                passion. You know it. Muhammad is
                someone with passion, someone that
                wants to make a dent in the
                Universe. What I like most about him
                is his never-ending commitment to
                add value.
                I worked with Muhammad
                while developing the
                www.gealliance.org program. (A
                program set up to coach train 1
                million entrepreneurs by 2027). He is
                someone with a strong work ethic,.
                someone that works well with others
                and generally nice guy.
                If you are
                looking for a mentor, a teacher, or a
                business partner, Muhammad would
                be a great choice.</p>
            </div>
            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/BryanCassady.jpg')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Bryan Cassady</div>
                <div className="designation">Director - Global Entrepreneurship Alliance</div>
                <div className="location">Brussels, Brussels Region, Belgium</div>
              </div>
            </div>
          </div>
        </Slider>

        <div className='researchHeading'>
          <h1>Team Member</h1>
        </div>
        <Slider {...settings} adaptiveHeight={false}
        >
          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">I have been working with
                Muhammad Owais Awan since
                2018 in Proeducation Platform For
                Champions (PPC), Islamabad. He
                is an amazing human being and a
                talented leader. He is focused on
                his works and gets the projects
                done no matter whatever
                situations are. He is a great time
                manager who always prefer to
                deliever the projects to the clients
                before time. I always feel fortunate
                to spend time in working with him.</p>
            </div>

            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/JahanzaibHashmi.jfif')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Jahanzaib Hashmi</div>
                <div className="designation">Administration Manager - PPC</div>
                <div className="location">Islamabad, Pakistan</div>
              </div>
            </div>
          </div>

          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">I am working with Owais from past 9
                to 10 years. He has been an example
                of hard work, dedication and
                cooperation based management
                person. I must say he is a blessed
                Time Manager who do manages his
                study, works and business at random
                making the person to think how he
                do it. His strategic & corporate
                communication, managerial and
                proactive approach make him to led
                among the best and take his
                initiatives to best of their insights. He
                do enough forecasting before
                holding a project and then moves on
                being committed to make it happen.</p>
            </div>
            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/KhawajaWajahatAhmad.jpg')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Khawaja Wajahat Ahmad</div>
                <div className="designation">Former Head of Finance -
                  PPC</div>
                <div className="location">Peshawar, Pakistan</div>
              </div>
            </div>
          </div>

          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">I know Owais from last 10 years. He
                has been my classmate, friend , team
                mate and my head at different
                instances. Owais is a dynamic,
                vibrant, proactive and a wonderful
                human. He is a professional who
                knows not only the ethics but the
                spirits of management. His approach
                towards his work is very positive and
                inspiring. He is the youngest CEO of
                one of a company which he built
                while he was merely an intermediate
                students. As a CEO, he has always
                motivated us and guided us to work
                more efficiently. I highly recommend
                him.</p>
            </div>
            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/ShahabUdDin.jpg')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Shahab Ud Din</div>
                <div className="designation">Former Chief Commercial Officer -
                  PPC</div>
                <div className="location">Peshawar, Pakistan</div>
              </div>
            </div>
          </div>
        </Slider>
        <div className='researchHeading'>
          <h1>Student</h1>
        </div>
        <Slider {...settings} adaptiveHeight={false}
        >
          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">Mr. Awan who has been my
                student at undergraduate level
                during his stay at Industrial
                engineering department UET
                Peshawar is an exceptional
                student, I have encoutered in my
                teaching experience. I have taught
                him in my Design of Experiments
                className where his performance was
                excellent. His most inspiring
                quality to me is that he always
                maintain a balance between the
                academics and entrepreneurship.
                He was among the top three
                students of his batch and I wish
                him a very good luck for his future
                endeavors.</p>
            </div>

            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/DrMisbah Ullah.jpg')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Prof. Dr. Misbah Ullah</div>
                <div className="designation">Chairman, Industrial Engineering UET Peshawar</div>
                <div className="location">Peshawar, Pakistan</div>
              </div>
            </div>
          </div>

          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">I have known Owais for about two to
                three years during his time at
                Industrial Engineering department,
                University of Engineering and
                Technology Peshawar. He has a
                deep passion for design thinking and
                engineering innovations. His
                thorough engineering knowledge
                blended with the ability to design the
                initiatives is supported by his co-
                curricular activities, which he
                displayed by winning many prizes
                during his academic carrier. He
                always prefers to maintain a balance
                between the academics and
                community social service projects.</p>
            </div>
            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/DrImran Ahmad.jpg')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Dr. Imran Ahmad</div>
                <div className="designation">Assistant Professor, Industrial Engineering UET Peshawar</div>
                <div className="location">Peshawar, Pakistan</div>
              </div>
            </div>
          </div>

          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">As Owais's engineering teacher I can
                tell, that he has proved to have a
                divergent, abstract thinking style,
                native intelligence and good problem
                solving skills, along with good
                interpersonal skills and leadership
                qualities. He also has abilities to
                handle conflicts and work in a team,
                which came to be important in the
                field of engineering management.
                He
                regularly helped me in organizing
                and carrying out latest skills
                workshops and seminars in the
                department. He showed himself as a
                reliable, ready to help person with a
                good ability to communicate with
                people of different ages.</p>
            </div>
            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/AbdurRehmanBabar.jpg')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Engr. Abdur Rehman</div>
                <div className="designation">Lecturer, Industrial Engineering UET Peshawar</div>
                <div className="location">Peshawar, Pakistan</div>
              </div>
            </div>
          </div>

          <div className="card swiper-slide">
            <div className="card-content">
              <p className="description">I had the pleasure of working with Owais at UET Peshawar and I would say he stands out both professionally and socially. I had the opportunity to discuss several ideas during different projects and he proved not only wonderful to work with but also has exceptional expertise in the EdTech domain.</p>
            </div>
            <div className="image-content">
              <span className="overlay"></span>

              <div className="card-image">
                <img src={require('./assets/img/numanKhurshid.jfif')} alt="" className="card-img" />
              </div>
              <div className='info'>
                <div className="name" style={{ padding: '0' }}>Numan Khurshid</div>
                <div className="designation">Assistant Professor | Deep Learning Scientist</div>
                <div className="location">Peshawar, Pakistan</div>
              </div>
            </div>
          </div>
        </Slider>
        <Footer />
      </div>
    </div >
  );
}
export default Testimonials