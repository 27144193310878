import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'


import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from '@cloudinary/react';

const Training = () => {

  var cld;
  cld = new Cloudinary({
    cloud: {
      cloudName: 'drsqh0aog'
    }
  });

  var mainCoverPhoto = cld.image('/Owais/coverPhotoforTraining_aiyqoz.jpg');

  return (
    <div className='main'>
      <Navbar />
      <div>
        <div className='mainImage'>
          <div className="mainPageHeading">
            Master Classes and Training</div>
          <AdvancedImage cldImg={mainCoverPhoto} />
        </div>


        <div className='pageMainHeading'>
          <span>I am a compassionate educator and leader known for being sincerely enthusiastic and passionate about learning and aim to empower those around me by encouraging entrepreneurship, edtech, strategic thinking, innovation and taking action. I value student-centred decision making and consistently promote the collaborative analysis of student data to inform research proven instructional practices for high impact learning opportunities. My hope is that we all take the necessary time and energy to choose wisely. I will help you build your natural confidence so you can take action toward your dreams and create your own version of success.</span>
        </div>


        <div className='trainingContent'>
          <div id='section_1'>
            <div className='typoSection'>
              <h3>Two Days Professional Training Workshop on “Digital Methods and Strategies for making effective presentations and meaningful teaching style in the classroom using CANVA”.</h3>
              <h5>Date: 12-June-2022 & 13-June-2022</h5>
              <p>This workshop was organized by the Training and Development Department of Proeducation Platform For Champions for the faculty members of schools and colleges in Peshawar, Pakistan. The core objective of this workshop was to enable the teachers to understand the concept of using technology integration in their lesson planning and classroom lectures to get the desire attention of the students. Besides that, many applications related to digital pedagogy and their integration with CANVA was briefed by the instructor. A large number of faculty members from the biggest Cambridge educational systems in Peshawar namely Beaconhouse School System, Roots Millennium Schools, International Islamic University Schools O&A Levels etc. joined the workshop and got a chance to learn the experience of CANVA and exercising it during the session. </p>
            </div>
            <div className="grid-wrapper">
              <div className="grid-header">
                <img src={require('./assets/img/training_img1.png')} />
              </div>
              <div className="grid-main">
                <div className="grid-content">
                  <div className="block">
                    <img src={require('./assets/img/training_img4.png')} />
                  </div>
                  <div className="block">
                    <img src={require('./assets/img/training_img3.png')} />
                  </div>
                  <div className="block">
                    <img src={require('./assets/img/training_img2.png')} />
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div id='section_1'>
            <div className='typoSection'>
              <h3>One Day Professional Workshop on “Embracing the Power of LinkedIn for better opportunities and career building”.</h3>
              <h5>Date: 28-February-2022</h5>
              <p>This workshop was organized jointly by The Millennium Universal College (TMUC) Bahria Springs, Islamabad and Training & Development Department of Proeducation Platform For Champions. Students from different departments of the campus including Business, Law, Fashion Designing, ACCA and computing participated in the workshop. The students were given the introduction about the importance of LinkedIn platform and its benefits in the career building. Some unique techniques and tips were briefed to the attendees on how to project the core academic abilities and technical skills to the global recruiters. The following points were covered during the workshop session:
                1. LinkedIn Introduction & Overview.
                2. Profile Making Tips and Techniques.
                3. Digital CV/Resume Understanding.
                4. Leads Generation and Socialization.</p>
            </div>

            <div className="grid-wrapper">
              <div className="grid-header">
                <img src={require('./assets/img/training_img8.png')} />
              </div>
              <div className="grid-main">
                <div className="grid-content">
                  <div className="block">
                    <img src={require('./assets/img/training_img11.png')} />
                  </div>
                  <div className="block">
                    <img src={require('./assets/img/training_img10.png')} />
                  </div>
                  <div className="block">
                    <img src={require('./assets/img/training_img9.png')} />
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div id='section_1'>
            <div className='typoSection'>
              <h3>One Day Professional Workshop on “The best patterns of CV and Resume writing for the undergraduate students of different universities across Islamabad, Pakistan.”</h3>
              <h5>Date: 28-November-2021</h5>
              <p>This workshop was organized by the Training and Development Department of Proeducation Platform For Champions for the students of universities across Islamabad, Pakistan. Mr. Awan delivered a psychomotor learning oriented CV & Resume writing session to the students of colleges and universities. Besides the methods and techniques of composing CV and resume, he stressed upon the formatting and alignment of the content. He further demonstrated the platforms such as CANVA, Adobe Photoshop etc. for better professional portfolio building and designing. He also suggested the students to add good additional sections to put on a CV such as certifications, associations, languages, extra training and courses, conferences, publications, or notable awards.</p>
            </div>
            <div className="grid-wrapper">
              <div className="grid-header">
                <img src={require('./assets/img/training_img112.png')} />
              </div>
              <div className="grid-main">
                <div className="grid-content">
                  <div className="block">
                    <img src={require('./assets/img/training_img12.png')} />
                  </div>
                  <div className="block">
                    <img src={require('./assets/img/training_img13.png')} />
                  </div>
                  <div className="block">
                    <img src={require('./assets/img/training_img14.png')} />
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div id='section_2'>
            <div className='typoSection'>
              <h3>One Day Interactive Workshop on “Career Counseling and Entrepreneurship - You are a Champion but you just need to let it know!”</h3>
              <h5>Date: 15-March-2022</h5>
              <p>This workshop was organized jointly by Roots IVY International University, Islamabad and Training & Development Department of Proeducation Platform For Champions for the outgoing students of the college and university. The purpose of the session was to provide an effective career and life building counseling to the students through a unique strategy of “Dream – Drive – Desire – Done – Revise”. The session aims to develop a sense of importance of skills along with academics for the international university students. Students were briefed the importance of latest technical skills such as data science, machine learning, cyber security, immersive technologies and much more. More than 50 students attended the workshop. This sessions served as a foundation to aware, inspire and arm our youth with the entrepreneurial skillset, innovative mindset, and essential spirit through the power of motivational storytelling, design thinking concepts and understanding of emerging technologies that will enable them to live a sustainable life in this world.</p>
            </div>

            <div>
              <span>
                <img className='imgMargin' src={require('./assets/img/training_img5.png')} />
                <img className='imgMargin' src={require('./assets/img/training_img6.png')} />
                <img className='imgMargin' src={require('./assets/img/training_img7.png')} />
              </span>
            </div>
          </div>


          <div id='section_2'>
            <div className='typoSection'>
              <h3>One Day Professional Teachers Workshop on “Introducing the Story Oriented Beginning Approach (SOBA) in Teaching especially for Montessori & Primary Level Teachers to improve their pedagogical development methods".</h3>
              <h5>Date: 16-October-2021</h5>
              <p>This workshop was organized by the Training and Development Department of Proeducation Platform For Champions under “Techie Teachers Training Program” for the faculty members of Montessori and Primary school teachers at Air Foundation School System-Salar Campus, Jinnah Gardens Islamabad. The main points of the workshop included strategy and planning to follow a story oriented beginning approach by the teachers in their classes before starting a lesson. Different manual and digital techniques were taught to the attendees by the workshop instructor. The participants of the workshop were given an overview of CANVA digitalized tools for animated learning approach to develop the better concepts and interest of the students in subjects. Besides that the collaborative options on MURAL was also briefed to the teachers for better interconnected activities via distant approach mechanism. At the end of the workshop, the participants were given a chance to have their questions and answers session with the workshop instructor to clear there concepts and difficulties.</p>
            </div>
            <div>
              <span>
                <img className='imgMargin' src={require('./assets/img/training_img17.png')} />
                <img className='imgMargin' src={require('./assets/img/training_img18.png')} />
                <img className='imgMargin' src={require('./assets/img/training_img19.png')} />
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Training