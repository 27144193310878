import React from 'react'

import Navbar from './Navbar';
import Footer from './Footer'


import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from '@cloudinary/react';


const MyStory = () => {
  var cld;
  cld = new Cloudinary({
    cloud: {
      cloudName: 'drsqh0aog'
    }
  });

  var mainCoverPhoto = cld.image('/Owais/coverPhotoMyStoryPage_duzzd5.jpg');

  return (
    <div className='main'>
      <Navbar />
      <div className='myStoryWrap'>
        <div className='mainImage' style={{ marginTop: '1.9vw'}}>
          <div className="mainPageHeading">
            My Story</div>
          <div className='secondHeadingAbout'  style={{ fontSize: '2.4vw', width: '75%'}}>From reassembling toys to an engineering manager and an edtech entrepreneur!</div>
          <AdvancedImage cldImg={mainCoverPhoto} />
        </div>
        <div className='myStoryContent' style={{ marginTop: '3vw'}}>
          <div className="wrapper">
            <div className="paragraph">
              <p>When I was a young kid, I used to watch the planes flying in the sky and wonder how they are able to fly so high. Airplanes were not the only machines that fascinated me. During my childhood I had gathered lots of mechanical toys and used to open them up to find out how they work. I was fond of disassembling my toys and then making a drawing sheet to assemble them again. Besides that I always prefer to read the books of my elder cousins in free time because the illustrations and figures usually motivate me to learn about them. </p>
            </div>
            <div className="image-div-1">
            </div>
          </div>
          <div className="wrapper-2">
            <div className="paragraph">
              <p>I grew up in the family of engineers, and had the opportunity to observe them at work. During summer vacations, I would to go with my uncle to see the armored tanks being manufactured in Taxila. At other times, I would observe my father’s friend who was designing a medicine lab at the hospital. Although I was too young to understand these complex processes at that time, I had started developing a keen interest in the field of engineering. Consequently, choosing a field for higher education after high school was not difficult.</p>
            </div>
            <div className="image-div-2">
            </div>
          </div>
          <div className="wrapper-2">
            <div className="paragraph">
              <p>As I entered the engineering university, I got a chance to study what I had been wishing for. I chose industrial engineering as my field of study because I was motivated not only to understand the manufacturing processes of the products but the overall engineering management and product design as well. I spent a good deal of time studying interesting concepts about automation, robotics, industry standards, design of experiments and engineering economy. Besides studies, I was also involved in a number of extracurricular activities. As a member of the Robotics Club I took part in a flying contest and designed a remote control airplane.</p>
            </div>
            <div className="image-div-3">
            </div>
          </div>
          <div id='section_2'>
            <div className='typoSection'>
              <p style={{ fontSize: '1.7vw'}}>With the positive intent of educating the youth of Pakistan, I founded an EdTech startup in 2017 namely Proeducation Platform For Champions (PPC) that specializes in providing professional academic, corporate and skills learning through training programs, career counseling sessions using the psychomotor learning methods, e-learning techniques and immersive technologies. I am proud to say that my startup has trained over 3000+ students and 500+ teachers in a wide variety of technical and digital skills across different cities of Pakistan till today. This institute has given me a chance to interact with the national leaders such as ambassadors, policy makers and influencers within Pakistan and abroad. I along with my team contributed a great role during COVID-19 pandemic to establish strong relations between Pakistan and other countries including France, Japan, USA and China by inviting university professors to join virtual sessions on global changes, sustainability and industry 4.0.</p>
              <p style={{ width: '79%', fontSize: '1.7vw' }}>I welcome the students from diverse backgrounds to come together to learn and grow holistically and to become citizens of this world who think deeply, live faithfully, and lead passionately.</p>
            </div>
            <div>
              <span>
                <img className='imgMargin' src={require('./assets/img/myStory_5.png')} />
                <img style={{ marginTop: '2.9vw'}} className='imgMargin' src={require('./assets/img/myStory_6.png')} />
                <img style={{ marginTop: '2.9vw'}} className='imgMargin' src={require('./assets/img/NewPhoto2.jpg')} />
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default MyStory