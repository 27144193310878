import * as React from 'react';
import { InlineWidget } from "react-calendly";

import Footer from './Footer'
import Navbar from './Navbar'


const ContactMe = () => {

  return (
    <div className='main contactMe'>
      <Navbar/>
      <div className='containerWrap'>
        <h1>Let's get Connected!</h1>
        <h4>Do you think I can help you with your ideas?<br />
          Do you want to invite me as a speaker or instructor?</h4>
          <h6>Pick a time to chat and we’ll get in touch!</h6>
        <div style={{ flex: '1' }} className='calendlywrap'>
          <InlineWidget url="https://calendly.com/royalmoa007/30min"
            pageSettings={{
              backgroundColor: 'ffffff',
              hideEventTypeDetails: false,
              hideLandingPageDetails: true,
              primaryColor: '00a2ff',
              textColor: '4d5055'
            }}
          />
        </div>
      </div >
      <Footer />
    </div >


  )
}

export default ContactMe