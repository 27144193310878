import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';



const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" >
        Owais Portfolio
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding component={Link} to="/">
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'About Me'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to="/my-story">
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'My Story'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to="/master-class">
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Master Class'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to="/projects">
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Projects'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to="/awards">
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Awards'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to="/testimonials">
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Testimonials'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding component={Link} to="/contact-me">
          <ListItemButton sx={{ textAlign: 'center' }}>
            <ListItemText primary={'Contact Me'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'inline-block' }}>
      <AppBar component="nav" style={{ background: '#222438' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link style={{ display: "contents" }} to="/"><img className='owaisLogoNav' src={require('./assets/img/Owais-Logo-White.png')} /></Link>
          <div className='alignItemsRight'>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Button sx={{ color: '#fff' }} component={Link} to="/">About Me</Button>
              <Button sx={{ color: '#fff' }} component={Link} to="/my-story">My Story</Button>
              <Button sx={{ color: '#fff' }} component={Link} to="/master-class">Master Class</Button>
              <Button sx={{ color: '#fff' }} component={Link} to="/projects">Projects</Button>
              <Button sx={{ color: '#fff' }} component={Link} to="/awards">Awards</Button>
              <Button sx={{ color: '#fff' }} component={Link} to="/testimonials">Testimonials</Button>
              <Button sx={{ color: '#fff' }} component={Link} to="/contact-me">Contact Me</Button>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;

























































// import React, { useState } from 'react';
// import { FiMenu, FiX } from 'react-icons/fi';
// import { Link } from 'react-router-dom';

// const Navbar = () => {
//   const [open, setOpen] = useState(false);

//   const handleClick = () => {
//     setOpen(!open);
//   };

//   const closeMenu = () => {
//     setOpen(false);
//   };

//   return (
//     <nav className="navbar">
//       <Link to="/" className="nav-logo">
//         Logo
//       </Link>
//       <div onClick={handleClick} className="nav-icon">
//         {open ? <FiX /> : <FiMenu />}
//       </div>
//       <ul className={open ? 'nav-links active' : 'nav-links'}>
//         <li className="nav-item">
//           <Link to="/" className="nav-link" onClick={closeMenu}>
//             My Story
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/about-me" className="nav-link" onClick={closeMenu}>
//             About Me
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/portfolio" className="nav-link" onClick={closeMenu}>
//             Portfolio
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/entrepreneurship" className="nav-link" onClick={closeMenu}>
//             Entrepreneurship
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/honor-awards" className="nav-link" onClick={closeMenu}>
//            Honor & Awards
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/testimonials" className="nav-link" onClick={closeMenu}>
//           Testimonials
//           </Link>
//         </li>
//         <li className="nav-item">
//           <Link to="/invite-me" className="nav-link" onClick={closeMenu}>
//             Invite Me
//           </Link>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Navbar;