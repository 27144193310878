import React from 'react'
import { SocialIcon } from 'react-social-icons';

import { Link } from 'react-router-dom';



const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <img style={{ height: "34%", width: "59%" }} className='owaisLogo' src={require('./assets/img/Owais-Logo-White.png')} />
                        <p className="text-justify marginHead">I welcome everyone from the diverse backgrounds to come together to learn and grow holistically and to become citizens of this world who think deeply, live faithfully, and lead passionately.</p>
                    </div>
                    <div className="col-md-3">
                        <h6>Quick Links</h6>
                        <ul className="footer-links">
                            <li><Link to="/">About Me</Link></li>
                            <li><Link to="/my-story">My Story</Link></li>
                            <li><Link to="/master-class">Master Class</Link></li>
                            <li><Link to="/projects">Projects</Link></li>
                            <li><Link to="/awards">Awards</Link></li>
                            <li><Link to="/testimonials">Testimonials</Link></li>
                            <li><Link to="/contact-me">Contact Me</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h6>Islamabad, Pakistan</h6>
                        <h6 className='emailText'>contact@owaisawan.me</h6>
                    </div>
                </div>
                <hr className="small" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-sm-6 col-12">
                        <p className="copyright-text">Copyright by Muhammad Owais Awan. All Rights Reserved by © 2020</p>
                    </div>
                    <div className="col-md-4 col-sm-6 col-12">
                        <ul className="social-icons">
                            <li><a className="linkedin" target='_blank'><i><SocialIcon url="https://www.linkedin.com/in/royalowaisawan/" /></i></a></li>
                            <li><a className="skype" target='_blank' href="https://join.skype.com/invite/wr6bJ0Rd4Smv"><i>
                                <img className="skypeImg" src={require('./assets/img/skypePng.webp')} /></i></a></li>
                            <li><a className="facebook" target='_blank'><i><SocialIcon url="https://www.facebook.com/royalowaisawan" /></i></a></li>
                            <li><a className="insta" target='_blank'><i><SocialIcon url="https://www.instagram.com/royalowaisawan/" /></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer