import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from '@cloudinary/react';

const HonorAwards = () => {


  var cld;
  cld = new Cloudinary({
    cloud: {
      cloudName: 'drsqh0aog'
    }
  });

  var mainCoverPhoto = cld.image('/Owais/coverphotoAwards_wkbgnf.jpg');


  return (
    <div className='main'>
      <Navbar />
      <div>
        <div className='mainImage'>
          <div className="mainPageHeading">Honors & Awards</div>
          <AdvancedImage cldImg={mainCoverPhoto} />
        </div>

        <div className='researchHeading'>
          <h1>Academic & Research</h1>
        </div>
        <div className='mainWrapper'>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/8.1.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Certificate of Appreciation for 2nd Distinction in
                  Bachelors of Industrial Engineering (Batch-2017)” from Prof. Dr.
                  Iftikhar Hussain (Vice Chancellor University of Engineering &amp;
                  Technology Peshawar) during the internal departmental Annual
                  Award Ceremony at Industrial Engineering Department,
                  University of Engineering &amp; Technology (UET) Peshawar. Prof. Dr.
                  Sahar Noor (Chairman Industrial Engineering Department) and
                  Prof. Dr. Misbah Ullah was also present.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/7.1.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the Virtual Reality Headset from Honorable Prof. Dr.
                  Imran Ahmad (Research Supervisor) at Industrial Engineering
                  Department, University of Engineering &amp; Technology (UET)
                  Peshawar. This headset was purchased from the Final Year
                  Funding award received from National Grassroots ICT Research
                  Initiative-NGIRI (2020-21), Government of Pakistan.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/6.1.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Best IISE President 2020-21 Award” from Prof. Dr.
                  Iftikhar Hussain (Vice Chancellor University of Engineering &amp;
                  Technology Peshawar) during the Annual Meeting of Institute of
                  Industrial and System Engineers (IISE) held at Video Conference
                  Hall, University of Engineering &amp; Technology (UET) Peshawar.</span>
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.1.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Best Performance Award as IISE President 2019”
                  from Honorable Prof. Dr. Misbah Ullah (IISE Chief Advisor) during
                  the Annual Meeting of Institute of Industrial and System
                  Engineers (IISE) held at Video Conference Hall, University of Engineering &amp; Technology (UET) Peshawar.</span>
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/4.1.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Best Organizer Award” from Honorable Prof. Dr.
                  Iftikhar Hussain (Vice Chancellor University of Engineering &amp;
                  Technology (UET) Peshawar) during the closing ceremony of the
                  Two Days Youth Empowerment Workshop held at USPCASE,
                  University of Engineering &amp; Technology (UET) Peshawar.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/3.1.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Certificate for Best Event Host” from Honorable
                  Prof. Dr. Misbah Ullah (Conference Patron) during the closing
                  session of the Conference on Industry Sustainability and
                  Technology held at Video Conference Hall, University of
                  Engineering &amp; Technology (UET) Peshawar. Mr. Rashid Nawaz
                  (Assistant Professor Industrial Engineering Department UET
                  Peshawar) was also present.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/2.2.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “School Honor Shield for High Score All Over
                  Sections” from Honorable Ms. Shahdab Zahra (Headmistress
                  Roots School System) for securing highest score (1000/1100) in
                  Secondary School Certificate (SSC-Part-2) at Peshawar Model
                  School Boys-IV Dalazak Road Peshawar. Ms. Sadia Ateeq
                  (Principal Peshawar Model School Boys-IV) was also present.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/2.1.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “School Gold Medal” from Honorable Ms. Shahdab
                  Zahra (Headmistress Roots School System) for securing highest
                  score (1000/1100) in Secondary School Certificate (SSC-Part-2) at
                  Peshawar Model School Boys-IV Dalazak Road Peshawar.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/1.3.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Best Chief Proctor Award for Iqbal House” from
                  Honorable Ms. Tahira Abbasi (Principal Peshawar Model Degree Colleges
                  for Girls) for securing highest score (493/550) in Secondary
                  School Certificate (SSC-Part-1) at Peshawar Model School Boys-IV
                  Dalazak Road Peshawar. Ms. Rizwana Irfan (Principal Peshawar
                  Model School Boys-IV) was also present.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/1.2.jpg')} alt="" />
                </span>                </div>
              <div className="flip-box-back">
                <span>Receiving the “School Honor Shield for High Score All Over
                  Sections” from Honorable Ms. Tahira Abbasi (Principal Peshawar Model
                  Degree Colleges for Girls) for securing highest score (493/550) in
                  Secondary School Certificate (SSC-Part-1) at Peshawar Model
                  School Boys-IV Dalazak Road Peshawar. Ms. Sadia Ateeq (Vice-
                  Principal Peshawar Model School Boys-IV) was also present.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/1.1.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “School Gold Medal” from Honorable Ms. Tahira Abbasi
                  (Principal Peshawar Model Degree Colleges for Girls) for securing
                  highest score (493/550) in Secondary School Certificate (SSC-
                  Part-1) at Peshawar Model School Boys-IV Dalazak Road
                  Peshawar. Ms. Rizwana Irfan (Principal Peshawar Model School
                  Boys-IV) was also present.</span>
              </div>
            </div>
          </div>
        </div>
        <div className='researchHeading'>
          <h1>Entrepreneurial and Corporate</h1>
        </div>
        <div className='mainWrapperTwo'>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/6.0.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Executor Award” from Mr. Arif Najaf (Chief
                  Product Officer at Konstant Variables Pvt Ltd) in recognition of
                  excellent performance in the team building, project coordination
                  and talent acquisition for the PhotoGAUGE, USA project in the
                  first 06 months of employment.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.9.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Guest Panelist Award” from Ms. Muneeze
                  Muzaffar (Principal The Millennium School and College, Flagship
                  College, Islamabad) at the Inaugural Ceremony of the Millennial
                  Global Entrepreneurship Week – MGEW 2021 - a celebration of
                  youth Empowerment and Entrepreneurship jointly organized by
                  The Millennium Education in collaboration with Global
                  Entrepreneurship Network GEN Global, The Indus Entrepreneurs
                  TIE Islamabad, National Incubation Center NIC, JS Bank Limited,
                  Islamabad Chambers of Commerce and Industry ICCI and
                  KAUFFMAN Foundation.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.8.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Token of Appreciation” in recognition of pitching
                  the proposal of “Innovating the education &amp; industry with Virtual
                  &amp; Augmented Reality based Systems” from Honorable Engr. Tariq
                  Javed (Advisor on Innovation) at Pakistan Engineering Council
                  Headquarters, Islamabad on the recommendation of Federal
                  Minister for Science &amp; Technology Mr. Fawad Ahmad Chaudhry.</span>
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.7.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Book on China” from the China Windows
                  representative upon my first visit to China Window Gallery at
                  Peshawar, Pakistan.</span>
              </div>
            </div>
          </div>

          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.6.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Personality Grooming Instructor Certificate” from
                  Guest of Honor along with Ms. Sadia Ateeq (Principal Peshawar
                  Model School Boys-IV) at closing ceremony of Summer Camp
                  2019 held at Peshawar Model School Boys-IV organized by
                  Human Resource Development Center, Peshawar Model
                  Educational Institution.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.5.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Best Collaborating Partner Award” from Guest of
                  Honors Mr. Asim Arbab (Mayor Peshawar), Mr. Qasim (Deputy
                  Mayor Peshawar) and Mr. Muhammad Saad (CEO Sabrina
                  Banquets) at the inaugural ceremony of Grand Festival “EID
                  BAZAAR 2019” held at Sabrina Banquet Halls, Peshawar.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.4.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Guest Speaker Shield” from Honorable Guest of
                  Honor Mr. Farhan Khalid (AUSOM Faculty) at the interactive and
                  informational seminar on &quot;Learning, Training &amp; Practice of
                  Sustainable Development Goals (SDGs) set by the United
                  Nations&quot; held at Auditorium of AUSOM department, Air
                  University Islamabad jointly organized by Proeducation Platform
                  For Champions and DadaBhoy Foundation.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.3.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Best Career Development Ambassador Certificate
                  2017-18” from Honorable Guest of Honor Prof. Dr. Sahar Noor
                  (Chairman Industrial Engineering Department, UET Peshawar) in
                  recognition of outstanding supporting initiatives for internships of departmental students at industries in Peshawar at the Annual
                  Industrial Night held at University of Engineering and Technology
                  Peshawar.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.11.jpg')} alt="" />
                </span>
              </div>
              <div className="flip-box-back">
                <span>Receiving the “Co-Host of Event Shield” from Chief Guest
                  Honorable Mr. Nadeem Jan (Focal Person to Chief Minister of
                  KPK, Government of Pakistan) at the Inauguration ceremony of
                  the Plantation Campaign under plant for Pakistan 10 Billion Tree
                  Tsunami, an initiative of Prime Minister of Pakistan” held at
                  Government Girls Degree College, Chagharmatti, Peshawar
                  jointly organized by Proeducation Platform For Champions and
                  The Triumphant Organization.</span>
              </div>
            </div>
          </div>
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <span>
                  <img className='imageCard' src={require('./assets/img/5.2.jpg')} alt="" />
                </span>                </div>
              <div className="flip-box-back">
                <span>Receiving the “The Event Host Shield” from Chief Guest
                  Honorable Mr. Syed Ammar Hussain Jafri (Former Additional
                  Director General at Federal Investigation Agency, Pakistan) and
                  Prof. Dr. Sareer Badshah (Registrar Islamia College Peshawar) at
                  the closing ceremony of “One Day Workshop on Cyber Security
                  and Latest Technologies” held at Khyber Union Hall, Islamia
                  College Peshawar, Pakistan jointly organized by Proeducation
                  Platform For Champions and E-Pakistan.</span>
              </div>
            </div>
          </div>
        </div>


      </div>
      <Footer />
    </div>
  )
}

export default HonorAwards