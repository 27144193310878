import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar';

import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from '@cloudinary/react';



const Research = () => {

  
  var cld;
  cld = new Cloudinary({
    cloud: {
      cloudName: 'drsqh0aog'
    }
  });

  var mainCoverPhoto = cld.image('/Owais/coverPhotoResearchPage_ioaaka.jpg');

  return (
    <div className='main'>
      <Navbar />
      <div>
        <div className='mainImage'>
          <div className="mainPageHeading">Research and Projects</div>
          <AdvancedImage cldImg={mainCoverPhoto} />
        </div>


        <div>
          <div className='introWrap'>
            <p className='introPara'>As a human being, I cannot avoid being curious about something especially in the things present around me, and because of this curosity, I keep developing many questions in my mind. Exploring the real time scenarios and reading the case-studies helps me to find out majority of answers to my questions and I prefer to explain them in my way to bring them in a better shape for upcoming learners. I prefer to research in engineering, education, technology and especially the integration of immersive reality tech in education to make it more engaging and worth demanding.</p>
            <img className='chairmanPic' src={require('./assets/img/Chairman.jfif')} alt="" />
          </div>
        </div>

        <div>
          <div className='researchHeading'>
            <h1>Development of Virtual Reality (VR) Model for Operational <br /> Learning of Lathe Machine  during COVID-19 Pandemic. <br /> (Final Year Research Project in Undergraduate Degree )</h1>
            <h5>Duration: September 2020 - September 2021</h5>
          </div>
          <div>
            <div className='containerForVr'>
              <p className='vrParaGrapgh'>This study explains an emerging technology of Virtual Reality (VR) and its application in the psychomotor learning domain of manufacturing education to support the experimental workings. VR is an immersive technology that provides a 3D animated environment with human interaction. This technology supports the two major abilities concerned with a user that includes visualization and external interaction. The human interaction in the VR environment using the controllers enables a user to provide the necessary input and receives the required output.
                To implement the VR technology in manufacturing experimentation, the basis is achieved by developing a prototype model of lathe machine and its turning operation in the environment of VR headset.  This VR experiment of lathe machine shows a great amount of reduction in the maintenance and electricity costs. One of the main advantages of using this technology is during the current COVID-19 pandemic where due to isolation and social distancing the students are required to adopt distant learning.</p>
              <div className='pictureContainer'>
                <img className='vrImgOne' src={require('./assets/img/vrPicOne.jpg')} alt="" />
                <img className='vrImgTwo' src={require('./assets/img/vrPicTwo.jpg')} alt="" />
              </div>
            </div>
          </div>
        </div>



        <div>
          <div className='researchHeading'>
            <h1>“SCRML” career counseling strategy for students to guide them with a  productive blend of academics, technology, skills and entrepreneurship for sustainable living.
              (Research Project for my Startup Company)</h1>
            <h5>Duration: November 2020 - January 2022</h5>
          </div>
          <div className='scrmlContainer'>
            <p className='scrmlPara'>This study explains a variety of learning pathways that will aware, inspire and arm our youth with the entrepreneurial skillset, innovative mindset, and essential spirit through the power of motivational storytelling, design thinking concepts and understanding of emerging technologies that will enable them to live a sustainable life in this world. “SCRML” counseling strategy is based on five steps including story oriented beginning, converging mindsets, random interactive questions, moving average of interest and Learn, Earn and Lead concepts. The target population of this program is the unaware and unskilled youth from the
              different areas of Pakistan which is a significant portion of the society. These
              youths are faced with many socio-economic challenges that hinder them
              from progressing in life and making a meaningful contribution to their societies. The SCRML strategy will give them the capacity to develop and actualize ideas and engage in selfemployment.</p>
            <div className='scrmlImgContainer'>
              <img src={require('./assets/img/SCRML1.jpg')} alt='' />
              <img src={require('./assets/img/SCRML2.jpg')} alt='' />
            </div>
          </div>
        </div>


        <div>
          <div className='researchHeading'>
            <h1> Internet of things (IoT) based Traffic Signal Control system using Optimized Weight Elman Neural Network (OWENN) algorithm for solving the Traffic Congestion at University of Engineering and Technology, Peshawar Pakistan.
              (Complex Engineering Project in Undergraduate Degree)</h1>
            <h5>Duration: May 2020 - July 2021</h5>
          </div>
          <div className='scrmlContainer'>
            <p className='scrmlPara'>This study describes the automated ways on how to solve congestion problems at the junctions by physically constructing new facilities with technology supported transportation management systems using automation and control knowledge. The methodology of the IOT based Traffic Signal Control system using the microprocessor, shows that the this system takes the decision of controlling the traffic lights on the basis of the amount of traffic in each direction. It takes the decision in a way that if one direction of the place has more traffic, then it increases the waiting time based on the optimized traffic IoT values to provide sophisticated control and coordination to confirm that traffic moves as smoothly and safely as possible. Based on the working environment of University of Engineering and Technology (UET), Peshawar, this IOT based system is better because the flow of traffic within the university have certain limits at each junction. Therefore taking the average data from each junction will help to devise a proper junction or intersection traffic strategy so that the traffic signals shall be installed accordingly. Moreover the implementation of this solution will help track the traffic flow within the university very clearly. </p>
            <div className='scrmlImgContainer'>
              <img src={require('./assets/img/img1.jpg')} alt='' />
            </div>
          </div>


          <div>
            <div className='researchHeading'>
              <h1> A Complete Computer Aided Process Plan (CAPP) for a Nut Cracker Assembly from its parts designing till its assembly operations and overall configurations in SolidWorks 2018.
                (Complex Engineering Project in Undergraduate Degree)</h1>
              <h5>Duration: June 2020 - July 2021</h5>
            </div>
            <div className='scrmlContainer'>
              <p className='scrmlPara'>In this study, a complete nut cracker assembly process plan is explained ranging from its parts sketching and designing till its assembly operations and overall configurations. The software used during the design of the sketch from the given exploded view is the SolidWorks. Moreover the dimensions are assumed as per the calculations of each and every part in the SolidWorks environment. First of all the design features were completed by setting the dimensions. After that the parts are designed individually and in the last step the assembly of the developed parts is associated to come up with the finalized nut cracker assembly. The steps of CAPP which were applicable with the help of available data have been successfully explained and demonstrated through the project. The following steps of CAPP are followed during this project:<br />
                1.	Material selection<br />
                2.	Dimensioning & Design of parts in the Solid works.<br />
                3.	Assembling of Parts in the Solid works.<br />
                4.	Process selection & sequencing.<br />
                5.	Machine and tool selection<br />
              </p>
            </div>

            <div id="offices">
              <div className="row">
                <img src={require('./assets/img/image 3.jpg')} />
                <img src={require('./assets/img/image 2.jpg')} />
              </div>
              <div className="row">
                <img src={require('./assets/img/image 4.jpg')} />
                <img src={require('./assets/img/image 5.jpg')} />
              </div>
            </div>
            <div>

              <div className='scrmlContainer'>
                <p className='scrmlPara'>In this open ended lab project, a random material part has been selected as per dimensions provided and a specialized design has been made in the AutoCAD window to proceed workshop based operations such as measuring, cutting, drilling, filing, finishing etc. in the CNC workshop. The product that has been chosen for the open ended lab is “Lasani Wood”. This wood is most commonly used in frames and furniture related things. We manufactured it in CNC Milling. The project ensures the implementation of knowledge gained from the lab work in practical sense and most importantly the application of instruments has been applied to the extensive level. This lab provided an overall practical working on work-piece and program coding.
                </p>
              </div>




              <div>
                <div className='researchHeading'>
                  <h1> Fabrication on a wooden part by applying different manufacturing processes in CNC machine.
                    (Open Ended Lab Project in Undergraduate Degree)</h1>
                  <h5>Duration: January 2020 - February 2020</h5>
                </div>
                <div>
                  <div className='containerForVr'>
                    <p className='vrParaGrapgh'>In this open ended lab project, a random material part has been selected as per dimensions provided and a specialized design has been made in the AutoCAD window to proceed workshop based operations such as measuring, cutting, drilling, filing, finishing etc. in the CNC workshop. The product that has been chosen for the open ended lab is “Lasani Wood”. This wood is most commonly used in frames and furniture related things. We manufactured it in CNC Milling. The project ensures the implementation of knowledge gained from the lab work in practical sense and most importantly the application of instruments has been applied to the extensive level. This lab provided an overall practical working on work-piece and program coding.</p>
                    <div className='pictureContainer'>
                      <img className='vrImgOne' src={require('./assets/img/image 6.jpg')} alt="" />
                      <img className='vrImgTwo' src={require('./assets/img/image 8.jpg')} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Research