import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
  // .slick-slide {
  //   background-color: #222438;
  // }

const StyledSlider = styled(Slider)`

 .slick-slider {
   margin: 0 !important;
 }

 .slick-slide div {
   height: 600px;
   width: 100%
 }

 .slick-slide img {
   height: 100%; 
   width: 100%; 
   object-fit: contain
 }

 .slick-slide div:first-child {
   padding: 0;
 }
 

`;
export { StyledSlider };
