import React, { useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StyledSlider } from "./Box.styled";
import Footer from './Footer'
import Navbar from './Navbar';


import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from '@cloudinary/react';



const About = () => {
  const [showButton, setShowButton] = useState(false);

  var cld;
  cld = new Cloudinary({
    cloud: {
      cloudName: 'drsqh0aog'
    }
  });

  var mainCoverPhoto = cld.image('/Owais/coverPhotoAbout_ofwkva.jpg');
  var one = cld.image('/SlideShow/0_miauzr.jpg');
  var two = cld.image('/SlideShow/1_miauzr.jpg');
  var two = cld.image('/SlideShow/2_miauzr.jpg');
  var three = cld.image('/SlideShow/3_miauzr.jpg');
  var four = cld.image('/SlideShow/4_miauzr.jpg');
  var five = cld.image('/SlideShow/5_miauzr.jpg');
  var six = cld.image('/SlideShow/6_miauzr.jpg');
  var seven = cld.image('/SlideShow/7_miauzr.jpg');
  var eight = cld.image('/SlideShow/8_miauzr.jpg');
  var nine = cld.image('/SlideShow/9_miauzr.jpg');
  var ten = cld.image('/SlideShow/10_miauzr.jpg');
  var eleven = cld.image('/SlideShow/11_miauzr.jpg');
  var tweleve = cld.image('/SlideShow/12_miauzr.jpg');
  var thirteen = cld.image('/SlideShow/13_miauzr.jpg');
  var fourteen = cld.image('/SlideShow/14_miauzr.jpg');
  var fivteen = cld.image('/SlideShow/15_miauzr.jpg');
  var sixteen = cld.image('/SlideShow/16_miauzr.jpg');
  var seventeenth = cld.image('/SlideShow/17_miauzr.jpg');
  var eighteenth = cld.image('/SlideShow/18_miauzr.jpg');
  var nineteenth = cld.image('/SlideShow/19_miauzr.jpg');
  var twenty = cld.image('/SlideShow/20_miauzr.jpg');
  var twentyOne = cld.image('/SlideShow/21_miauzr.jpg');
  var twentyTwo = cld.image('/SlideShow/22_miauzr.jpg');
  var twentyThree = cld.image('/SlideShow/23_miauzr.jpg');
  var twentyFour = cld.image('/SlideShow/24_miauzr.jpg');
  var twentyFive = cld.image('/SlideShow/25_miauzr.jpg');
  var twentySix = cld.image('/SlideShow/26_miauzr.jpg');
  var twentySev = cld.image('/SlideShow/27_miauzr.jpg');
  var twentyEig = cld.image('/SlideShow/28_miauzr.jpg');
  var twentyNine = cld.image('/SlideShow/29_miauzr.jpg');
  var thirty = cld.image('/SlideShow/30_miauzr.jpg');
  var thirtyOne = cld.image('/SlideShow/31_miauzr.jpg');
  var thirtyTwo = cld.image('/SlideShow/32_miauzr.jpg');
  var thirtyThree = cld.image('/SlideShow/33_miauzr.jpg');
  var thirtyFour = cld.image('/SlideShow/34_miauzr.jpg');
  var thirtyFive = cld.image('/SlideShow/35_miauzr.jpg');


  const settings = {
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    dots: false
  };

  function handleChange() {
    setShowButton(true)
  }


  return (
    <div className='main'>
      <Navbar />
      <div className='sliderEdit'>
        <div className='mainImage'>
          <AdvancedImage cldImg={mainCoverPhoto} />
          <div className='mainHeadingAbout'>HELLO, I'M MUHAMMAD OWAIS AWAN!</div>
          <div className='secondHeadingAbout'>I strongly believe “You are a Champion but you just need to know about it!”</div>
        </div>
        <div className='trainingContent' style={{ marginTop: '3.5vw' }}>
          <div className="wrapper">
            <div className="paragraph">
              <p>I am a technology management professional and a compassionate educator known for being sincerely enthusiastic and passionate about learning and aim to empower those around me by encouraging effective management, technology solutions, EdTech, strategic thinking, innovation, entrepreneurship and taking action. I value team-centered decision making and consistently promote the collaborative analysis of team member's data to inform research proven instructional practices for high impact learning opportunities</p>
            </div>
            <div className="about-me-1">
            </div>
          </div>
          <div className="wrapper-2">
            <div className="paragraph">
              <p>I am the founder and Chief Executive Officer of Proeducation Platform For Champions, an EdTech institute that specializes in providing professional academic, corporate and skills learning through training programs, career counseling sessions and one on one tutoring using the effective, psychomotor learning methods, e-learning techniques, technology oriented teaching approach and immersive technologies. The institute has trained over 3000+ students and 500+ teachers in a wide variety of artistic and digital skills across different cities of Pakistan. </p>
            </div>
            <div className="about-me-2">
            </div>
          </div>
          {showButton ? <div>
            <div className="wrapper-2">
              <div className="paragraph">
                <p>In the field of entrepreneurship, I have worked with Prof. Bryan Cassady while developing the Global Entrepreneurship Alliance program that aims to coach and train 1 million entrepreneurs by 2027. I attended the GEA international workshops in 2020. I am also a contributor in his book “The Cycles”.</p>
              </div>
              <div className="about-me-3">
              </div>
            </div>
            <div className='wrapper-2'>
              <div className='paragraph'>
                <p>In the field of career counseling and skill development, I along with my team developed a variety of learning pathways that will aware, inspire and arm our youth with the entrepreneurial skillset, innovative mindset, and essential spirit through the power of motivational storytelling, design thinking concepts and understanding of emerging technologies that will enable them to live a sustainable life in this world. My most unique contributions is the “SCRML” counseling strategy that is based on five steps including story oriented beginning, converging mindsets, random interactive questions, moving average of interest and Learn, Earn and Lead concepts.</p>
              </div>
              <div className="about-me-4">
              </div>
            </div>
            <div className='wrapper-2'>
              <div className='paragraph'>
                <p>In the field of Engineering and Technology, I have initiated a project "PAK-VRTECH", an innovative psychomotor learning system supported by Virtual and Augmented Reality as a solution for the practical laboratory learning for students of educational institutions during the times of COVID-19. This project was recommended by Pakistan Innovation and Testing Center (PITC) of Pakistan Engineering Council in 2020 and accepted in National Grassroots ICT Research Initiative (NGIRI) in 2020-21 in Pakistan.</p>
              </div>
              <div className="about-me-5">
              </div>
            </div>
          </div> : <button className='showMoreButton' onClick={handleChange}>Read More</button>}
        </div>


        <div className='researchHeading'>
          <h1>Community Engagements</h1>
        </div>


        <StyledSlider {...settings}>
          <div>
            <div>
              <AdvancedImage cldImg={one} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={two} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={three} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={four} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={five} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={six} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={seven} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={eight} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={nine} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={ten} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={eleven} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={tweleve} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={thirteen} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={fourteen} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={fivteen} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={sixteen} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={seventeenth} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={eighteenth} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={nineteenth} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twenty} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentyOne} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentyTwo} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentyThree} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentyFour} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentyFive} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentySix} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentySev} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentyEig} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={twentyNine} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={thirty} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={thirtyOne} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={thirtyTwo} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={thirtyThree} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={thirtyFour} />
            </div>
          </div>
          <div>
            <div>
              <AdvancedImage cldImg={thirtyFive} />
            </div>
          </div>
        </StyledSlider>


        <div className='researchHeading'>
          <h1>Expertise</h1>
        </div>

        <div className="expertiseContainer">
          <div className="grid-item">Engineering & Operations Management</div>
          <div className="grid-item">Corporate Strategic Communications</div>
          <div className="grid-item">Project Management & Scheduling</div>
          <div className="grid-item">Design Thinking & Entrepreneurship Development</div>
          <div className="grid-item">Team building & Leading</div>
          <div className="grid-item">Policies Planning & Composing</div>
          <div className="grid-item">EdTech Planning & Development</div>
          <div className="grid-item">Enterprise Resource Planning</div>
          <div className="grid-item">Information Systems and Management</div>
        </div>

        <div className='researchHeading'>
          <h1>Technical Toolkit to Innovation</h1>
        </div>

        <div className="skillsContainer">
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/msoffice.png')} /></div>
            <div>MS OFFICE</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/minitab.png')} /></div>
            <div>MINITAB / ODDO</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/asana.png')} /></div>
            <div>ASANA / MURAL</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/jira.png')} /></div>
            <div>JIRA / TRELLO</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/autocad.png')} /></div>
            <div>AUTOCAD</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/blender.png')} /></div>
            <div>BLENDER</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/unity.png')} /></div>
            <div>UNITY 3D</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/filmora.png')} /></div>
            <div>FILMORA / CAMTASIA</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/canva.png')} /></div>
            <div>CANVA</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/wordpress.png')} /></div>
            <div>WORDPRESS</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/figma.png')} /></div>
            <div>FIGMA</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/photoshop.png')} /></div>
            <div>ADOBE PHOTOSHOP</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/lingo.png')} /></div>
            <div>TORA / LINGO</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/simio.png')} /></div>
            <div>SIMIO / ARENA</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/Matlab.png')} /></div>
            <div>MATLAB / ARDUINO</div>
          </div>
          <div className="grid-item">
            <div className='skillLogo'><img src={require('./assets/Skills Images/python.png')} /></div>
            <div>PYTHON</div>
          </div>
        </div>

        <div className='researchHeading'>
          <h1>Growth Cycle</h1>
        </div>

        <section>
          <div className='growthImage'></div>
        </section>

        <div className='researchHeading'>
          <h1>Poetry at my Best!</h1>
        </div>
        <div className='poemWrap'>
          <ol>
            <li><a href="https://medium.com/@royalowaisawan/thats-called-life-a-poem-f476f8b095ee" target='_blank'>That’s Called Life!</a></li>
            <li><a href="https://medium.com/@royalowaisawan/covid-human-re-defining-a-poem-2025304ef9c2" target='_blank'>COVID and Human Redefining...</a></li>
            <li><a href="https://medium.com/@royalowaisawan/i-love-switzerland-4c752bf11fd5" target='_blank'>I Love Switzerland...</a></li>
            <li><a href="https://medium.com/@royalowaisawan/emirates-is-the-best-979f479c1112" target='_blank'>Emirates is the BEST!</a></li>
          </ol>
        </div>
        <Footer />
      </div>
    </div>
  )
}


export default About